@font-face {
  font-family: "PetraScriptEF";
  src: local("PetraScriptEF"), url(./fonts/Petra\ Script\ EF\ Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Autograf";
  src: local("Autograf"), url(./fonts/AutografPersonalUseOnly-mOBm.ttf) format('truetype');
}

@font-face {
  font-family: "Antically";
  src: local("Antically"), url(./fonts/AnticallyRegular-OVox8.ttf) format('truetype');
}

@font-face {
  font-family: "JustSignature";
  src: local("JustSignature"), url(./fonts/JustSignature-92w7.ttf) format('truetype');
}

@font-face {
  font-family: "Adinda Melia";
  src: local("Adinda Melia"), url(./fonts/Adinda\ Melia.ttf) format('truetype');
}

@font-face {
  font-family: "Andina";
  src: local("Andina"), url(./fonts/Andina\ Demo.ttf) format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.css-djr471 > :not(style) + :not(style) {
  margin: 0; /* Set the margin to 0 */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.content {
  background-image: url("../src/images/backgroundv5.jpg");
  background-repeat:no-repeat;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}

@media only screen and (max-width: 910px) {
  .content {
    background-image: none;
    background-color: #fff;
  }
}

.MuiPagination-root .MuiPagination-ul {
  flex-wrap: nowrap;
}
.MuiPagination-root .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.MuiPagination-root .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: 'previous';
}
.MuiPagination-root .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MuiPagination-root .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: 'next';
}

.signaturePadCanvas {
  width: 100%;
  height: 100%;
}


.css-1wbh8iq-MuiStack-root>:not(style)+:not(style) {
  margin: 0;
  margin-top: 10px !important;
}