@font-face {
  font-family: "PetraScriptEF";
  src: local("PetraScriptEF"), url(../fonts/Petra\ Script\ EF\ Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Autograf";
  src: local("Autograf"), url(../fonts/AutografPersonalUseOnly-mOBm.ttf) format('truetype');
}

@font-face {
  font-family: "Antically";
  src: local("Antically"), url(../fonts/AnticallyRegular-OVox8.ttf) format('truetype');
}

@font-face {
  font-family: "JustSignature";
  src: local("JustSignature"), url(../fonts/JustSignature-92w7.ttf) format('truetype');
}

@font-face {
  font-family: "Adinda Melia";
  src: local("Adinda Melia"), url(../fonts/Adinda\ Melia.ttf) format('truetype');
}

@font-face {
  font-family: "Andina";
  src: local("Andina"), url(../fonts/Andina\ Demo.ttf) format('truetype');
}

.pdf_page_custom{
    position: 'relative';
    /* top: 0;
    left:0; */
    width:'100%';
    height:'100%';
    /* border: 10px solid red; */
  }
  
  .react-resizable {
    position: relative;
  }

  .css-13v8mlk-MuiStack-root>:not(style)+:not(style) {
    margin: 0;
    margin-top: 20px !important;
  }

  .react-resizable-handle {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    right: 0;
    /* z-index: 99; */
    /* background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCwIMNi/6x/7tAAAAYUlEQVQoz5WRyw2AMAxDnzoCEpXYfyN6YIl2hnAonyDqIJLri5040LCrK69KTHxUn91YIqBgFLIGZlatYhhESD12z7ERscoP5GnURtF5lX5AgAjgNpLAqSIA/19Lw2wdvANHn0NQzRpaRQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMS0wMlQxMjo1NDo0NyswMDowMNPrWX4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTEtMDJUMTI6NTQ6NDcrMDA6MDCituHCAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTExLTAyVDEyOjU0OjQ3KzAwOjAw9aPAHQAAAABJRU5ErkJggg=="); */
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
    /* border-right: 2px solid red;
    border-bottom: 2px solid red; */
    /* border-radius: 50%; */
    /* box-shadow: 0 2px 5px rgb(0 0 0 / 25%); */
    /* background: red; */
  }
  #content {
    width: 100%;
    background: transparent;
    padding-bottom: 200px;
  }
  .layoutRoot {
    display: flex;
    flex-wrap: wrap;
  }
  .editor-box {
    display: flex;
    background: #1684013b;
    border: 1px solid #1684013b;
    opacity: 0.8;
    border-radius: 5px;
    text-align: center;
    padding: 0px;
    box-sizing: border-box;
    /* margin-bottom: 10px; */
    overflow: hidden;
    position: relative;
    /* margin: 20px; */
    
  }

  .editor-box-element-with-text {
    display: inline-block;
    background: #1684013b;
    border: 1px solid #1684013b;
    border-radius: 2px;
    padding: 0px;
    box-sizing: border-box;
    /* margin-bottom: 10px; */
    overflow: hidden;
    position: relative;
    /* margin: 20px; */
  }

  .editor-box .text {
    /* text-align: center; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 36px;
  }
  .editor-box3 .react-resizable-handle {
    display: none;
  }

  .editor-box3 {
    background: #E2EBF5;
    border: 1px solid #E2EBF5;
    border-radius: 5px;
    padding: 0px;
  }

  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }